<template>
    <div class="content">
        <my-head :index="3"></my-head>
        <div class="top-box">
            <img src="@/assets/icon/bgTop.png" class="top-img" />
            <div class="top-info">
                <div class="top-title">吨顿返团</div>
                <div class="top-retitle">入驻加盟</div>
                <div class="top-retitle">介绍</div>
                <div class="top-etitle">互惠共生 合作共赢</div>
                <div class="top-etitle">MUTUAL BENEFIT,SYMBIOSIS, COOPERATION,AND WIN-WIN SITUATION</div>
            </div>

        </div>
        <div class="box">
            <div class="c-tit">DEALER</div>
            <div class="e-tit">市级独家代理简介</div>
            <div class="e-col">公司秉承合作共赢的合作理念特此推出市级独家代理，一个人，一座城，开通此权限者可在自身权限城市(包含下属区县)内任意发展下限</div>
            <div class="e-col">并从中抽取流水，管理简单，利润可观，成为市级独家代理人仅需向公司缴纳100000元加盟代理费即可开通市级独家代理权限，享受市级代</div>
            <div class="e-col">理人的高额流水分红</div>
            <div class="list">
                <div class="col-item">
                    
                </div>
            </div>
        </div>



        <my-bottom :index="3"></my-bottom>
    </div>
</template>
   
<script>
import myHead from '@/components/my-head.vue'
import myBottom from '@/components/my-bottom.vue'
export default {
    components:{
        myHead,
        myBottom
    },
    name: 'joinUsPage',
    async created() {
    }
}
</script>
<style scoped>
.top-img{
    width: 100vw;
}

.top-box{
    width: 100vw;
    height: calc(295/1503*100vw);
    overflow: hidden;
    position: relative;
}

.top-info{
    width: 80%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.top-title{
    color: rgba(255, 255, 255, 1);
    font-size: calc( var(--vas) * 1.1 );
    text-align: right;
    padding-top: calc( var(--vas) * 2 );
    padding-bottom: calc( var(--vas) * .6 );
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.top-retitle{
    color: rgba(255, 255, 255, 1);
    font-size: calc( var(--vas) * 1.1 );
    text-align: right;
    margin-top: calc( var(--vas) * .3 );
}

.top-etitle{
    color: rgba(255, 255, 255, .7);
    font-size: calc( var(--vas) * .6 );
    text-align: right;
    margin-top: calc( var(--vas) * .3 );
}


.box{
    padding:calc(var(--vas) * 4) calc(var(--vas) * 8) 0;
}
.c-tit{
    text-align: center;
    font-size: calc(var(--vas) * 2);
    color: rgba(121, 121, 121, 1);
}

.e-tit{
    text-align: center;
    font-size: calc(var(--vas) * 1.5);
    margin-top: calc(var(--vas) * .8);
    margin-bottom: calc(var(--vas) * .8);
}

.e-col{
    text-align: center;
    font-size: calc(var(--vas) * .8);
    color: rgba(121, 121, 121, 1);
    line-height: calc(var(--vas) * 1.5);
   
}
</style>