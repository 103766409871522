<template>
    <div class="content">
        <my-head :index="2"></my-head>
        <img src="@/assets/icon/pageI.jpg" class="pageI" />
        <my-bottom :index="2"></my-bottom>
    </div>
</template>
   
<script>
import myHead from '@/components/my-head.vue'
import myBottom from '@/components/my-bottom.vue'
export default {
    components:{
        myHead,
        myBottom
    },
    name: 'introductionPage',
    async created() {
    }
}
</script>
<style scoped>
.pageI {
    width: 100vw;
    display: block;
}
</style>
   