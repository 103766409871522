<template>
    <div class="bottom-page">
       <div class="foot-box">站点导航<label class="foot-c"> / SITE NAVIGATION</label></div>
       <div class="list foot-c">
        <div class="item" :class="{active:index==0}" @click="goPage('/')">首页</div>
        <div class="item" :class="{active:index==1}" @click="goPage('/aboutus')">关于我们</div>
        <div class="item" :class="{active:index==2}" @click="goPage('/introduction')">产品介绍</div>
        <!-- <div class="item" :class="{active:index==3}" @click="goPage('/joinUs')">入驻加盟</div> -->
        <div class="item" :class="{active:index==4}" @click="goPage('/contact')">联系我们</div>
       </div>
       <div class="tit-info foot-c">
        <div class="tit-phone">电话：139-90816662</div>
        <div class="tit-phone">地址：四川省南充市顺庆区文化广场2期写字楼15楼</div>
       </div>
       <div class="foot-t foot-c"><a class="a-link" href="https://map.baidu.com/search/%E5%9B%9B%E5%B7%9D%E7%9C%81%E5%8D%97%E5%85%85%E5%B8%82%E9%A1%BA%E5%BA%86%E5%8C%BA%E6%96%87%E5%8C%96%E5%B9%BF%E5%9C%BA2%E6%9C%9F%E5%86%99%E5%AD%97%E6%A5%BC15%E6%A5%BC/@11809300.296662621,3585309.545,15.33z?querytype=s&da_src=shareurl&wd=%E5%9B%9B%E5%B7%9D%E7%9C%81%E5%8D%97%E5%85%85%E5%B8%82%E9%A1%BA%E5%BA%86%E5%8C%BA%E6%96%87%E5%8C%96%E5%B9%BF%E5%9C%BA2%E6%9C%9F%E5%86%99%E5%AD%97%E6%A5%BC15%E6%A5%BC&c=75&src=0&pn=0&sug=0&l=13&b=(11784113,3573387;11845297,3602955)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1" target="_blank">四川省先迈网络服务有限责任公司</a> —— <a class="a-link" href="https://beian.miit.gov.cn/?site=brandsite&from=footer#/Integrated/index" target="_blank">蜀ICP备2022007818号</a></div>
    </div>
</template>
  
<script>
export default {
    async created() {
    },
    props:{
        index:{
            type:Number,
            default:0
        }
    },
    methods: {
        goPage(type){
            this.$router.replace(type)
        }
    },
    mounted() {

    }

}
</script>
<style scoped>
    .bottom-page{
        background-color: #383d41;
        padding: calc(var(--vas)) calc(var(--vas)*8) 0;
    }
    .foot-box{
        color: rgba(255, 255, 255, 1);
        font-size: calc(var(--vas));
    }

    .foot-box label{
        font-size: calc(var(--vas) * .6);
    }
    .foot-c{
        color: #979897;
    }

    .list{
        display: flex;
        font-size: calc(var(--vas) * .6);
        margin-top: calc(var(--vas) );
        border-bottom: 1px solid #9798977c;
    }
    .item{
        margin-right: calc(var(--vas) * 6);
        padding-bottom: calc(var(--vas) * .4);
        cursor: pointer;
    }

    .item:hover{
        color: rgba(255, 255, 255, 1);
    }

    .item.active{
        color: rgba(255, 255, 255, .9);
    }

    .tit-info{
        padding: calc(var(--vas) ) 0;
        font-size: calc(var(--vas) * .5);
        border-bottom: 1px solid #9798977c;
    }

    .foot-t{
        text-align: center;
        font-size: calc(var(--vas) * .5);
        padding: calc(var(--vas) * .5);
    }

    .a-link{
        color: #979897;
    }

    .a-link:hover{
        color: rgba(255, 255, 255, 1);
    }
</style>
  