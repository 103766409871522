<template>
  <router-view/>
</template>

<style>
*{
    --vas:16/1080*100vw;
}

body{
    margin: auto;
    width: 100vw;
    overflow-x: hidden;
}

div{
  -webkit-user-select:none; /* WebKit内核私有属性 */
  -ms-user-select:none; /* IE私有属性(IE10及以后) */
  -khtml-user-select:none; /* KHTML内核私有属性 */
  -o-user-select:none; /* Opera私有属性 */
  user-select:none; /* CSS3属性 */
}
</style>
