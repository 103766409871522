<template>
    <div class="head-page">
        <img src="@/assets/icon/logo.png" class="head-logo"  @click="goPage('/')" />
        <div class="head-btn">
            <button class="btn" @click="goPage('/')" :class="{active:index==0}">首页</button>
            <button class="btn" @click="goPage('/aboutus')" :class="{active:index==1}">关于我们</button>
            <button class="btn" @click="goPage('/introduction')" :class="{active:index==2}">产品介绍</button>
            <!-- <button class="btn" @click="goPage('/joinUs')" :class="{active:index==3}">入驻加盟</button> -->
            <button class="btn" @click="goPage('/contact')" :class="{active:index==4}">联系我们</button>
        </div>
    </div>
</template>
  
<script>
export default {
    async created() {
    },
    props:{
        index:{
            type:Number,
            default:0
        }
    },
    data() {
        return {

        }
    },
    methods: {
        goPage(type){
            this.$router.replace(type)
        }
    },
    mounted() {
       
    }

}
</script>
<style scoped>
.head-page {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px calc(var(--vas)*4);
}

.head-logo {
    width: calc(var(--vas)*5);
}

.btn {
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
    border: none;
    font-size: calc(var(--vas)*0.7);
    color: rgba(0, 0, 0, 1);
    margin-left: calc(var(--vas)*0.8);
}

.btn:hover{
    color: rgba(239, 84, 3, .5);
}

.active{
    color: rgba(239, 84, 3, 1);
}
</style>
  