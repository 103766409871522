<template>
  <div class="page-info">
    <div class="page-pc">
    <my-head :index="0"></my-head>
    <div class="box-banner">
      <img src="@/assets/icon/banne.png" class="banner-index" />
      <div class="title-box">
        <div class="title-box-title">吨顿返团</div>
        <div class="title-box-retitle">DUNDUNFANTUAN</div>
        <div class="title-box-rule">让<span style="color:#ed5404">买单</span>变得更加轻松省钱</div>
        <ul class="tab-list">
          <li class="tab-list-item">万元抵扣金</li>
          <li class="tab-list-item">买单立减</li>
          <li class="tab-list-item">全店折扣</li>
          <li class="tab-list-item">方便快捷</li>
          <li class="tab-list-item">优惠力度大</li>
        </ul>
        <img src="@/assets/icon/btnT.png" @click="goPage('/introduction')" class="btn-1"/>
      </div>
    </div>
    <div class="intro-box">
      <div class="intro-box-content">
        <div class="intro-box-t">
          <div class="intro-t">四川省先迈网络服务有限责任公司</div>
          <div class="intro-rt">始于2023年</div>
          <p class="intro-p">“四川先迈网络服务有限责任公司”成立于2021年，立足西南，业务辐射全国。吸纳了程序开发、视觉设计、市场营销等各方面人才。我们拥有多平台团购小程序、技术运营一体化解决方案，是一家围绕着本地生活领域，为用户带来全生态数字化服务的公司。</p>
          <p class="intro-p">“吨顿返团”是我司打造的一款全新消费平台，主要服务用户和商家。该平台集吃、喝、玩、乐、游、购、娱为一体，为用户提供一站式的生活消费服务，通过“万元抵扣金，支付立减，惠享生活”的形式给消费者带来实惠的同时为商家进行引流。</p>
          <button class="btn-intro" @click="goPage('/aboutus')">
            公司简介
            <img src="@/assets/icon/btnI.png"/>
          </button>
        </div>
        <div class="intro-b">
          <div class="intro-b-t">
            <label>别团购、随时、随地、享受全店优惠折扣</label>
            <img src="@/assets/icon/icon1.png" class="icon-1"/>
          </div>
          <div class="intro-b-rt">Farewell to group buying, anytime, anywhere, and enjoy store wide discounts</div>
        </div>
      </div>
      <img src="@/assets/icon/intor.png" class="intro-img"/>
    </div>
    <div class="join-box">
      <div class="join-t">
        <div class="join-t-t">招商加盟</div>
        <div class="join-t-rt">FRANCHISE PARTNERSHIP</div>
      </div>
      <div class="join-rt">互利共生、合作共赢!经济合伙人代理加盟计划正式启动</div>

      <div class="join-content" >
        <img src="@/assets/icon/b1.png" class="join-content-img" />
        <div class="join-content-info">
          <img src="@/assets/icon/tit1.png" class="join-content-info-icon" />
          <div class="join-content-info-rule">公司秉承合作共赢的合作理念特此推出市级独家代理，一个人，一座城。开通此权限者可在自身权限城市 (包含下属区县) 内发展下限。</div>
          <div class="join-content-info-btn">
            <label>加盟热线：139-90816662</label>
            <img src="@/assets/icon/icon2.png" class="join-content-info-btn-icon"/>
          </div>
        </div>
      </div>

      <div class="join-content"  >
        <img src="@/assets/icon/b2.png" class="join-content-img" />
        <div class="join-content-info black-bg">
          <img src="@/assets/icon/tit2.png" class="join-content-info-icon" />
          <div class="join-content-info-rule">公司秉承合作共赢的合作理念特此分配区县独家代理权限，开通此权限者可在自身区县范围内发展下限。</div>
          <div class="join-content-info-btn">
            <label>加盟热线：139-90816662</label>
            <img src="@/assets/icon/icon3.png" class="join-content-info-btn-icon"/>
          </div>
        </div>
      </div>
    </div>
    <my-bottom :index="0"></my-bottom>
    </div>
    <img src="@/assets/icon/pageI.jpg" class="mobile-img"/>
  </div>
</template>

<script>
import myHead from '@/components/my-head.vue'
import myBottom from '@/components/my-bottom.vue'
export default {
    components:{
        myHead,
        myBottom
    },
  name: 'IndexPage',
  async created() {
  },
  data() {
    return {

    }
  },
  methods: {
    goPage(type){
      this.$router.replace(type)
    }
  },
  mounted() {

  }

}
</script>
<style scoped>
.banner-index {
  width: 100vw;
  display: block;
}

.box-banner {
  position: relative;
  width: 100vw;
  height: calc(453/1192*100vw);
  overflow: hidden;
}

.title-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: rgba(255, 255, 255, 1);
}

.title-box-title {
  font-size: calc(var(--vas)*1);
  padding: calc(var(--vas)*4) calc(var(--vas)*8) 0;
  letter-spacing: calc(var(--vas)*0.2);
}

.title-box-retitle {
  font-size: calc(var(--vas)*0.8);
  margin-top: calc(var(--vas)*0.001);
  letter-spacing: calc(var(--vas)*0.1);
  opacity: 0.9;
  padding-left: calc(var(--vas)*8);
}

.title-box-rule {
  font-size: calc(var(--vas) * 1.4);
  margin-top: calc(var(--vas) * 1.2);
  padding-left: calc(var(--vas)*8);
  letter-spacing: calc(var(--vas)*0.3);
}

.tab-list{
  display: flex;
  padding-left: calc(var(--vas)*8);
  margin-top: calc(var(--vas) * 1.2);
}

.tab-list-item{
  margin-left: calc(var(--vas) );
  margin-right: calc(var(--vas));
  font-size: calc(var(--vas)*.6);
}

.btn-1{
  width: calc(var(--vas) * 8);
  margin-top: calc(var(--vas) * 5);
  margin-left: calc(var(--vas) * 8);
  display: block;
  cursor: pointer;
}

.btn-1:hover{
  opacity: .8;
}

.intro-box{
  position: relative;
}

.intro-box-t{
  padding: calc(var(--vas)*4) calc(var(--vas)*8);
}

.intro-t{
  font-size: calc(var(--vas) * 1.2);
  color: #ef5404;
}

.intro-rt{
  font-size: calc(var(--vas) * 1);
  color: #ef5204a8;
  margin-top: calc(var(--vas) * .1);
  margin-bottom: calc( var(--vas) * .6);
}

.intro-p{
  font-size: calc(var(--vas) * .6);
  margin-bottom: calc(var(--vas) * .2);
  width: calc(var(--vas) * 28);
  text-indent:calc(var(--vas) * .5);
  color: rgba(22, 22, 22, 1);
  line-height: calc(var(--vas) * 1.2);
}

.intro-b{
  background-color: #ef5404;
  padding:calc(var(--vas)* 2) calc(var(--vas)*8);
}

.intro-b-t{
  font-size: calc(var(--vas) * .9);
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}

.intro-b-t img{
  height: calc(var(--vas) * .9);
  margin-left:calc(var(--vas) * .6);
}

.intro-b-rt{
  color: rgba(255, 255, 255, 1);
  font-size: calc(var(--vas) * .7);
  margin-top: calc(var(--vas) * .2);
}


.btn-intro{
  background-color: #ef5404;
  color: rgba(255, 255, 255, 1);
  border: none;
  width: calc(var(--vas) * 4.3);
  height: calc(var(--vas) * 1.6);
  margin-top: calc(var(--vas) * 1.8);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--vas) * .6);
  cursor: pointer;
}

.btn-intro:hover{
  opacity: .8;
}

.btn-intro img{
  width: calc(var(--vas) * .8);
  margin-left: calc(var(--vas) * .2);
}


.intro-img{
  position: absolute;
  z-index: 1;
  right: calc(var(--vas)*8);
  bottom: 0;
  height: calc(var(--vas)*25);
}

.join-box{
  padding: calc(var(--vas)*3) calc(var(--vas)*8);
}

.join-t{
  font-size: calc(var(--vas) * 1.2);
  display: flex;
  align-items: self-end;
  color: rgba(0, 0, 0, 1);
}

.join-t-t{
  position: relative;
  padding-right: calc(var(--vas) * .6);
}

.join-t-t::before{
  content: '';
  position: absolute;
  width: 1px;
  height: calc(var(--vas) * 1);
  right: 0;
  top: calc(var(--vas) * .5);
  background-color: rgba(0, 0, 0, 1);
}

.join-t-rt{
  font-size: calc(var(--vas) * 1);
  margin-left: calc(var(--vas) * .6);
  color: rgba(88,88,88,1);
}

.join-rt{
  color: rgba(88,88,88,1);
  font-size: calc(var(--vas) * .7);
  margin-top: calc(var(--vas) * .12);
}

.join-content{
  display: flex;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  margin-top: calc(var(--vas) * 2);
}

.join-content:hover{
  transform: scale(1.01);
}

.join-content-img{
  height: calc(var(--vas) * 12);
  width: calc( 743 / 283 * var(--vas) * 12);
}

.join-content-info{
  height: calc(var(--vas) * 12);
  width: calc(100% -  743 / 283 * var(--vas) * 12);
  background-color: #f4f4f4;
  position: relative;
}

.join-content-info-icon{
  margin: calc( var(--vas) * 1 );
}

.join-content-info-rule{
  padding: 0 calc( var(--vas) * 1.3 );
  margin-top: calc( var(--vas) * .3 );
  font-size: calc( var(--vas) * .7 );
  line-height: calc( var(--vas) * 1.4 );
  text-indent: calc(var(--vas) * 1);
}

.join-content-info-btn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: calc( var(--vas) * 1.3 );
  bottom: calc( var(--vas) * .8 );
}

.join-content-info-btn-icon{
  height: calc( var(--vas) * 1 );
}

.black-bg{
  background-color: #333333;
  color: rgba(255, 255, 255, 1);
}

.join-content-info-icon{
  width: calc( var(--vas) * 8 );
}

@media screen and (max-width: 600px) {
    .page-pc{
      display: none;
    }
    .mobile-img{
      display: block;
      width: 100vw;
    }
}

@media screen and (min-width: 600px) {
  .page-pc{
      display: block;
  }
  .mobile-img{
      display: none;
      width: 100vw;
    }
}

</style>
