import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'
import aboutus from '../views/aboutus.vue'
import contact from '../views/contact.vue'
import introduction from '../views/introduction.vue'
import joinUs from '../views/joinUs.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: { title: '首页-吨顿返团' }
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus,
    meta: { title: '关于我们-吨顿返团' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: { title: '联系我们-吨顿返团' }
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction,
    meta: { title: '产品介绍-吨顿返团' }
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: joinUs,
    meta: { title: '入驻加盟-吨顿返团' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
