<template>
    <div class="content">
        <my-head :index="1"></my-head>
        <div class="bg-box">
            <img src="@/assets/icon/b3.png" class="bg-img an-img"  />
            <div class="info-box">
                <div class="title-box">
                    <div class="title-bg">ABOUT</div>
                    <div class="title-name">关于我们</div>
                </div>
                <p class="cal-p">
                    “四川先迈网络服务有限责任公司”成立于2021年，立足西南，业务辐射全国。吸纳了程序开发、视觉设计、市场营销等各方面人才。我们拥有多平台团购小程序、技术运营一体化解决方案，是一家围绕着本地生活领域，为用户带来全生态数字化服务的公司。
                </p>
                <p class="cal-p">
                    “吨顿返团”是我司打造的一款全新消费平台，主要服务用户和商家。该平台集吃、喝、玩、乐、游、购、娱为一体，为用户提供一站式的生活消费服务，通过“万元抵扣金，支付立减，惠享生活”的形式给消费者带来实惠的同时为商家进行引流。
                </p>
                <img src="@/assets/icon/icona.png" class="img-icon" />
            </div>
        </div>
        <div class="bg-box">
            <img src="@/assets/icon/bg5.png" class="bg-img" />
            <div class="left-box io-box" :class="{ lacAnimation: falg }">
                <div class="title">企业使命</div>
                <div class="rule">为助力中国梦的幸福生活，同心同行，共享共赢!</div>
            </div>
            <div class="right-box  io-box" :class="{ racAnimation: falg }">
                <div class="title">核心概念</div>
                <div class="rule">为助力中国梦的幸福生活，同心同行，共享共赢!</div>
            </div>
        </div>
        <div class="bg-box">
            <img src="@/assets/icon/b4.png" class="bg-img" :class="{rigit:cocol}" style="position: absolute;z-index: 2;" />
            <div class="info-box">
                <div class="title-box">
                    <div class="title-bg">BUSINESS</div>
                    <div class="title-name">主营业务</div>
                </div>
                <p class="cal-p">
                    目前我司主要以“吨顿返团”产品为核心开展经营。“吨顿返团”是一款集吃、喝、玩、乐、游、购、娱为一体的本地生活小程序，致力于服务用户和商家，打造一站式的生活消费体验，无论是线上团购还是线下消费，不论是消费者还是入驻商家，都可享受到立即返现的福利。
                </p>
                <p class="cal-p">
                    区别于目前各团购平台的单品团购，走本地生活路线的“吨顿返团”采取全场折扣的形式，加大对消费者的吸引力度，增强为商家引流变现。同时设置“万元抵扣金”，消费者登录“吨顿返团”小程序自动送万元抵扣金，消费使用“吨顿返团”小程序支付即可使用抵扣。结账时，不论消费多少，每笔都有优惠立减，增送万元，用完为止。
                </p>
                <img src="@/assets/icon/iconb.png" class="img-icon" />
            </div>
        </div>
        <div class="box-col">
            <img src="@/assets/icon/t1.png" class="tit-img" />
            <div class="col-re">“吨顿返团”核心业务团队目前由市场部、销售部、策划设计部及程序部等组成，汇集大量各岗位人才，均具备行业多年</div>
            <div class="col-re">从业经验，在各自领域承担重要职责。</div>
            <div class="col-list">
                <div class="col-item">
                    <img class="col-img" src="@/assets/icon/l1.png" />
                    <div class="col-tit">市场部</div>
                    <div class="col-retit">负责研究市场趋势，分析竞争对手，制定并执行市场营销策略，以提升公司的品牌知名度和市场份额。</div>
                </div>
                <div class="col-item">
                    <img class="col-img" src="@/assets/icon/l2.png" />
                    <div class="col-tit">销售部</div>
                    <div class="col-retit">负责产品的销售工作，包括开发新客户，维护老客户，达成销售目标等。</div>
                </div>
                <div class="col-item">
                    <img class="col-img" src="@/assets/icon/l3.png" />
                    <div class="col-tit">策划设计部</div>
                    <div class="col-retit">负责公司的产品设计和策划工作，包括产品规划、设计、测试和优化等，以确保产品的质量和用户体验。</div>
                </div>
                <div class="col-item">
                    <img class="col-img" src="@/assets/icon/l4.png" />
                    <div class="col-tit">程序部</div>
                    <div class="col-retit">主要负责开发和维护公司的小程序，以便提供给用户更便捷的服务</div>
                </div>
            </div>


        </div>

        <div class="wh-box">
            <img src="@/assets/icon/t2.png" class="wh-tit-img" />
            <div class="wh-nux">
                <img src="@/assets/icon/c1.png" class="wh-ct" />
                <div class="wh-info">
                    <div class="wh-item">
                        <div class="wh-item-tit">用户为先</div>
                        <div class="wh-item-con">优质服务，体验为王。关注用户需求，以用户为先是一切可持续合作的基础。</div>
                    </div>
                    <div class="wh-item">
                        <div class="wh-item-tit">协作创新</div>
                        <div class="wh-item-con">开放协作、创新未来。对内对外都应秉持开放协作的观念。创新未来合作模式，成就更大商机。</div>
                    </div>
                    <div class="wh-item">
                        <div class="wh-item-tit">极致专注</div>
                        <div class="wh-item-con">尽职尽责、始终专注。始终保持专注的态度，脚踏实地，把事情做到极致，少许诺，多兑现。</div>
                    </div>
                    <div class="wh-item">
                        <div class="wh-item-tit">勇敢进取</div>
                        <div class="wh-item-con">勇于承担，超越自我。永远保持好奇心，敢为人先，不断追求卓越，超越自我。</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="wl-box">
            <img src="@/assets/icon/bg6.png" class="wl-bg-img" />
           <div class="wl-box-content">
                <img class="wl-box-content-img" src="@/assets/icon/tit4.png" />
                <div class="wl-box-rule" style="margin-top: calc(var(--vas) * 2);">平台现已入驻各大影院、麦当劳、肯德基、瑞幸咖啡、星巴克等众多大型品牌连锁店，且覆盖本地各行业入驻商家 300+，</div>
                <div class="wl-box-rule">且未来将持续邀请更多的大型连锁门店及各地热门商家入驻我们将为消费者创造省钱、省心的消费环境，同时为商户的</div>
                <div class="wl-box-rule">经营发展提供支持，促进城市经济复苏。</div>
                <div class="wl-box-content-box">
                    <img class="wl-box-content-box-limg" :class="{animationImgL:bbtome}" src="@/assets/icon/l7.png" />
                    <img class="wl-box-content-box-rimg" :class="{animationImgR:bbtome}" src="@/assets/icon/l8.png" />
                </div>
           </div>
        </div>

        <my-bottom :index="1"></my-bottom>
    </div>
</template>
  
<script>
import myHead from '@/components/my-head.vue'
import myBottom from '@/components/my-bottom.vue'
export default {
    components: {
        myHead,
        myBottom
    },
    name: 'AboutusPage',
    data() {
        return {
            ridio: 0,
            falg: false,
            cocol:false,
            bbtome:false
        }
    },
    async mounted() {
        this.ridio = 932 / 1520;
        window.addEventListener('scroll', this.scrollToTop)
    },
    methods: {
        scrollToTop() {
            let clientWidth = window.clientWidth || document.documentElement.clientWidth;
            let heightg = clientWidth * this.ridio;
            let pageScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (pageScroll > heightg * 0.5) {
                this.falg = true
            } else {
                this.falg = false
            }
            if (pageScroll > heightg * 1) {
                this.cocol = true
            } else {
                this.cocol = false
            }
            if(pageScroll > heightg * 4.1){
                this.bbtome = true
            }else{
                this.bbtome = false
            }
        }
    }
}
</script>
<style scoped>
.bg-box {
    width: 100vw;
    height: calc(845/1503*100vw);
    position: relative;
}

.bg-img {
    width: 100vw;
    height: calc(845/1503*100vw);
    display: block;
}

.info-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.title-box {
    position: relative;
    width: calc(var(--vas) * 25);
    margin-left: calc(var(--vas) * 7);
}

.title-box::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(188, 188, 188, .2);
    left: 0;
    bottom: 2px;
}

.title-box::after {
    content: '';
    position: absolute;
    width: calc(var(--vas) * 2);
    height: 3px;
    background-color: #ee5404;
    left: 0;
    bottom: 2px;
}

.title-bg {
    font-size: calc(var(--vas) * 7);
    color: rgba(188, 188, 188, .2);
    font-weight: bold;
}

.title-name {
    position: absolute;
    bottom: calc(var(--vas) * 1.5);
    left: 0;
    font-size: calc(var(--vas) * 2);
    font-weight: bold;
}

.cal-p {
    font-size: calc(var(--vas) * .6);
    margin-left: calc(var(--vas) * 7);
    width: calc(var(--vas) * 25);
    line-height: calc(var(--vas) * 1.2);
}

.cal-p:nth-child(2) {
    margin-top: calc(var(--vas) * 3);
}

.img-icon {
    width: calc(var(--vas) * 22);
    margin-left: calc(var(--vas) * 8);
    margin-top: calc(var(--vas) * 2);
}

.bg-box .left-box {
    position: absolute;
    top: calc(var(--vas) * 21);
    right: calc(50% + (var(--vas) * 5));
    text-align: right;
}

.bg-box .right-box {
    position: absolute;
    top: calc(var(--vas) * 21);
    left: calc(50% + (var(--vas) * 5));
    text-align: left;
}

.bg-box .io-box {
    opacity: .9;
    min-width: calc(var(--vas) * 16);
}

.bg-box .io-box .title {
    font-size: calc((var(--vas) * 1));
    color: #d7750f;
    font-weight: bold;
}

.bg-box .io-box .rule {
    font-size: calc(var(--vas) * .6);
    color: #ffffff;
    margin-top: calc(var(--vas) * 1);
}

.lacAnimation {
    animation: kesleft 3s linear 1 backwards;
}

.racAnimation {
    animation: kesright 3s linear 1 backwards;
}

.rigit{
    animation: lertkes 2s linear 1 backwards;
}

.box-col{
    margin-top: calc(var(--vas) * 2);
}

.box-col .tit-img{
    width: calc(var(--vas) * 16);
    display: block;
    margin: auto;
    margin-bottom: calc(var(--vas) * 3);
}

.box-col .col-re{
    text-align: center;
    font-size: calc(var(--vas) * .8);
    line-height: calc(var(--vas) * 2);
    color: rgba(88, 88, 88, 1);
}

.col-list{
    display: flex;
    justify-content: space-between;
    padding:  0 calc(var(--vas) * 7);
    margin-top: calc(var(--vas) * 2);
    padding-bottom: calc(var(--vas) * 2);
}

.col-list .col-item{
    background-color: #ff7200;
    padding: calc(var(--vas) * .5);
    border-radius: calc(var(--vas) * .5);
    width: calc((100% - (var(--vas) * 6))/4);
}

.col-list .col-img{
    width: 100%;
    height: calc((100vw - (var(--vas) * 20)) / 4 - var(--vas));
    display: block;
}

.col-list .col-tit{
    font-size: calc(var(--vas) * .8);
    color: rgba(255, 255, 255, 1);
    background-color: #4f4f4f;
    text-align: center;
    width: calc(var(--vas) * 8);
    height: calc(var(--vas) * 1.6);
    line-height: calc(var(--vas) * 1.6);
    border-radius: calc(var(--vas) * .9);
    margin: auto;
    position: relative;
    margin-top: calc(var(--vas) * .8 * (-1));
}

.col-list .col-retit{
    font-size: calc(var(--vas) * .6);
    color: rgba(255, 255, 255, 1);
    line-height: calc(var(--vas) * 1);
    margin-top: calc(var(--vas) * 1.6);
    height: calc(var(--vas) * 6);
}

.wh-box{
    padding: calc(var(--vas) * 2) calc(var(--vas) * 7);
}

.wh-tit-img{
    width: calc(var(--vas) * 27);
    display: block;
    margin: auto;
}

.wh-nux{
    margin-top: calc(var(--vas) * 2);
    background-color: #c7c7c7;
    padding: calc(var(--vas) * .6);
    border-radius: calc(var(--vas) * .4);
    display: flex;
    align-items: center;
}

.wh-ct{
    width: calc(40%);
}

.wh-info{
    width: calc(60% - (var(--vas) * 3.4));
    margin-left: calc(var(--vas) * 2);
}

.wh-item-tit{
    font-size: calc(var(--vas) * .8);
    font-weight: bold;
    color: rgba(88, 88, 88, 1);
    padding-bottom: calc(var(--vas) * .5);
    border-bottom: 1px solid rgba(88, 88, 88, .6);
}

.wh-item{
    padding-left: calc(var(--vas) * 1);
    position: relative;
}

.wh-item::before{
    content: '';
    position: absolute;
    height: calc(var(--vas) * .6);
    width: calc(var(--vas) * .6);
    background-color: rgba(88, 88, 88, .6);
    border-radius: 50%;
    left: 0;
    top: calc(var(--vas) * .32);
}

.wh-item:not(:first-child){
    margin-top: calc(var(--vas) * .3);
}

.wh-item-con{
    color: rgba(88, 88, 88, 1);
    margin-top: calc(var(--vas) * .5);
}

.an-img{
    animation: onsani 2s linear 1 backwards;
}



.wl-box{
    width: 100vw;
    height: calc(820/1476 * 100vw);
    position: relative;
}

.wl-bg-img{
    width: 100vw;
    height: calc(820/1476 * 100vw);
}

.wl-box-content{
    width: 100vw;
    height: calc(820/1476 * 100vw);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.wl-box-content-img{
    width: calc(var(--vas) * 23);
    display: block;
    margin: auto;
    padding-top: calc(var(--vas) * 6);
}

.wl-box-content-box{
    display: flex;
    justify-content: center;
    margin-top: calc(var(--vas) * 2);
}

.wl-box-content-box-rimg{
    width: calc(var(--vas) * 16);
    height: calc(421/442*(var(--vas) * 16));
    margin-left: 100vw;
}

.wl-box-content-box-limg{
    width: calc(var(--vas) * 16);
    height: calc(421/442*(var(--vas) * 16));
}

.wl-box-rule{
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-size: calc(var(--vas) * 1);
    margin-top: calc(var(--vas) * .31);
}

.animationImgL{
    animation: lefImg 2s linear 1 backwards;
}

.animationImgR{
    margin-left: calc(var(--vas) * 2);
    animation: rightImg 2s linear 1 backwards;
}

@keyframes rightImg{
    0%{
        opacity: 0;
        margin-left: 100vw;
        transform: rotate(360deg)
    }
    100%{
        transform: rotate(0deg);
        opacity: 1;
        margin-left: calc(var(--vas) * 2);
    }
}

@keyframes lefImg{
    0%{
        opacity: 0;
        transform: rotate(-360deg);
    }
    100%{
        transform: rotate(0deg);
        opacity: 1;
    }
}




@keyframes onsani{
    0%{
        opacity: 0;
        margin-left: 50%;
    }
    100%{
        opacity: 1;
        margin-left: 0%;
    }
}

@keyframes lertkes{
    0%{
        opacity: 0;
        left: 20%;
    }
    100%{
        opacity: 1;
        left: 0%;
    }
}

@keyframes kesleft{
    0%{
        opacity: 0;
        right: 100%;
    }
    100%{
        opacity: .9;
        right: calc(50% + (var(--vas) * 5));
    }
}
@keyframes kesright{
    0%{
        opacity: 0;
        left: 100%;
    }
    100%{
        opacity: .9;
        left: calc(50% + (var(--vas) * 5));
    }
}
</style>
  