<template>
    <div class="content">
        <my-head :index="4"></my-head>
        <div class="content-box">
            <img src="@/assets/icon/bg4.png" class="content-bg" />
            <div class="info-box">
                <div>电话：139-90816662</div>
                <div>地址: 四川省南充市顺庆区文化广场2期写字楼15楼</div>
                <div>四川省先迈网络服多有限责任公司</div>
            </div>
        </div>
        <my-bottom :index="4"></my-bottom>
    </div>
</template>
   
<script>
import myHead from '@/components/my-head.vue'
import myBottom from '@/components/my-bottom.vue'
export default {
    components:{
        myHead,
        myBottom
    },
    name: 'contactPage',
    async created() {
    }
}
</script>
<style scoped>
.content-bg {
    width: 100vw;
    display: block;
}

.content-box{
    width: 100vw;
    height: calc(845/1503 * 100vw);
    overflow: hidden;
    position: relative;
}

.info-box{
    display: flex;
    position: absolute;
    justify-content: space-between;
    padding: calc( var(--vas) * .3 ) calc( var(--vas) * 6 );
    background-color: #ee5404;
    color: rgba(255, 255, 255, 1);
    width: calc(100vw - (var(--vas) * 12));
    bottom: calc( var(--vas) * 6 );
    left: 0;
    font-size: calc( var(--vas) * .6 );
    opacity: .8;
}
</style>
   